.dataManagementNavbar {
}

:global {
  :local(.dataManagementNavbar) {
    // nav
    .ant-tabs-nav {
      width: 250px;
      padding: 2rem 2rem 2rem 0;
      box-sizing: content-box;
    }

    // active tab in nav
    & .ant-tabs-tab-active {
      background-color: #e6f7ff;
    }
    // content wrapper
    .ant-tabs-content-holder {
      border: none;
      padding: 2rem 0 2rem 0;
    }
  }
}
