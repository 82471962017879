@import 'src/ui/variables.module';

.comment {
  color: $grey7;
  white-space: pre-line;
}

.actionsContainer {
  margin-top: 14px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.commentsBox {
  margin-bottom: 24px;
}

.comments {
  margin-bottom: 8px;
}
