@import 'src/ui/variables.module';
@import 'src/ui/print.module';

.form {
  margin-top: 14px;
}

.actionsContainer {
  margin-top: 14px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.readOnly {
  color: $grey7;
  white-space: pre-wrap;
}

:global {
  :local(.form) > .ant-form-item {
    margin-bottom: 22px;
  }
}
