@import 'src/ui/variables.module';
@import 'src/ui/print.module';

.mainContainer {
  border: solid;
  border-color: $blueBorders;
  border-width: 2px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 16px;
}

.mailForm {
  max-width: 400px;
}
