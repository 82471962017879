@import 'src/ui/variables.module';

.header {
  display: flex;
  justify-content: space-between;
  align-content: space-evenly;
  background-color: $header;
}

.logo {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 18px;
}

.menu {
  flex: 1;
  justify-content: center;
  background-color: transparent;
  color: white;
  border-bottom-width: 0;
}

.menuItem {
  border-bottom-width: 0;
}

.avatarContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.userDropdown {
  & .infos {
    padding: 8px 12px;
    font-style: italic;

    &:hover {
      background-color: inherit;
      cursor: inherit;
    }

    & * {
      display: inline-block;
      padding: 1px 0;
    }

    & .user {
      font-weight: 600;
      font-style: normal;
    }
  }
}

:global {
  :local(.menuItem).ant-menu-item-selected {
    background: transparent;
    border-bottom-width: 0;

    & > .ant-menu-title-content {
      font-weight: bold;
      text-decoration: underline;
      color: white;

      & :hover {
        color: white;
        text-decoration: underline;
      }
    }
    & :hover {
      color: white;
      text-decoration: underline;
    }
  }

  .ant-menu-horizontal:not(.ant-menu-dark)
    > :local(.menuItem).ant-menu-item:hover {
    color: white; // force specific text color on hover
    text-decoration: underline;
  }

  .ant-menu-horizontal > :local(.menuItem).ant-menu-item::after,
  .ant-menu-horizontal > :local(.menuItem).ant-menu-submenu::after {
    border-bottom: 0 !important; // to rm small border on button when hover or selected
  }
}
