@import 'business/App.scss';

.card {
  width: 470px;

  padding: 40px;
  box-shadow: 0px 4px 12px rgba($tundora, 0.25);
  background: $white;
  border-radius: 20px;

  @include mobile {
    width: 100%;

    padding: 30px;
    box-shadow: none;
    border-radius: initial;
  }
}
