@import 'ui/variables.module.scss';

#root {
  width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
}

.separator {
  height: 16px;
}

.margin-auto {
  margin: auto;
}

//
// EFFECTS
//
.elevation1 {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.elevation2 {
  @include elevation2;
}

//
// TYPOS
//

.semibold2432 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.regular1422 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.regular1624 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.regular3846 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
}

//
// COLOR CLASSES
//
.black {
  color: $black;
}

.white {
  color: $white;
}

.bgWhite {
  background-color: $white;
}

.blue9 {
  color: $blue9;
}

.bgBlue9 {
  background-color: $blue9;
}

.blue5 {
  color: $blue5;
}

.bgBlue5 {
  background-color: $blue5;
}

.grey1 {
  color: $grey1;
}

.bgGrey1 {
  background-color: $grey1;
}

.grey6 {
  color: $grey6;
}

.grey8 {
  color: $grey8;
}

.bgGrey6 {
  background-color: $grey6;
}

//
// CUSTOM STYLES
//
.menuLink {
  color: $black;
  height: fit-content;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed $daybreak;
}

tr.drop-over-upward td {
  border-top: 2px dashed $daybreak;
}

.ant-tooltip-inner {
  background-color: $header;
}
