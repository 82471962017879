@import 'business/App.scss';

.appLogo {
  width: 102px;
}

@include mobile {
  .appLogo {
    width: 90px;
  }
}
