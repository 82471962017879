@import 'business/App.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;

  @include mobile {
    height: 100%;
    width: 100%;
    align-items: flex-start;
  }
}

.env {
  position: absolute;
  top: 0;
  left: 16px;
  background: white;
  padding: 8px 16px;
  border-radius: 0 0 4px 4px;
}
