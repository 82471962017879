.dataManager {
  max-width: 100%;
}

.table {
  min-height: 4rem;
  margin-top: 2rem;
}

.addNewEntity {
  margin-top: 1rem;
}

.searchForm {
  margin: 2em 0 2em 0;

  form {
    margin-top: 1.5em;
  }
}

:global {
  :local(.dataManager) {
    // row buttons
    .ant-btn-primary:not(:first-child),
    .ant-btn-default:not(:first-child) {
      margin-left: 1rem;
    }

    .ant-table-row {
      vertical-align: top;
    }
  }
}
