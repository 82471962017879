@media print {
  body {
    width: 21cm;
    height: 29.7cm;
    margin: 0;
    overflow: visible;
  }

  .printable {
    header {
      display: none;
    }
  }
}
