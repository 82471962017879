@import 'business/App.scss';

$sm-margin: 20px;
$md-margin: 40px;
$lg-margin: 80px;

$sm-margin-mobile: 8px;
$md-margin-mobile: 30px;
$lg-margin-mobile: 50px;

.spaceContainer {
  display: flex;
  width: 100%;

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-baseline {
    align-items: baseline;
  }

  &.justify-start {
    justify-content: start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &justify-center {
    justify-content: center;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.justify-space-evenly {
    justify-content: space-evenly;
  }

  &.horizontal {
    flex-direction: row;

    &.size-small {
      & > *:not(:last-child) {
        margin-right: $sm-margin;
        @include mobile {
          margin-bottom: $sm-margin-mobile;
        }
      }
    }

    &.size-middle {
      & > *:not(:last-child) {
        margin-right: $md-margin;
        @include mobile {
          margin-bottom: $md-margin-mobile;
        }
      }
    }

    &.size-large {
      & > *:not(:last-child) {
        margin-right: $lg-margin;
        @include mobile {
          margin-bottom: $lg-margin-mobile;
        }
      }
    }
  }

  &.vertical {
    flex-direction: column;

    &.size-small {
      & > *:not(:last-child) {
        margin-bottom: $sm-margin;
        @include mobile {
          margin-bottom: $sm-margin-mobile;
        }
      }
    }

    &.size-middle {
      & > *:not(:last-child) {
        margin-bottom: $md-margin;
        @include mobile {
          margin-bottom: $md-margin-mobile;
        }
      }
    }

    &.size-large {
      & > *:not(:last-child) {
        margin-bottom: $lg-margin;
        @include mobile {
          margin-bottom: $lg-margin-mobile;
        }
      }
    }
  }
}
