.layout {
  display: flex;
  flex: 1;
  min-height: 100vh;
}

.content {
  padding-top: 8px;
  padding-bottom: 48px;
  padding-left: 48px;
  padding-right: 48px;
}

.breadcrumbContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}
