@import 'src/ui/variables.module';

.common {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.container {
  z-index: -1;
}

.colorLayer {
  background-color: $darkBlue;
  opacity: 0.57;
}
